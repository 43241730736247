import Vue from 'vue'
// import { Preferences } from "@capacitor/preferences";


var estadoApp = new Vue({
  data: {
    cargandoOverlay: false,
    emailOlvidado:'',
    version:'v1.0.2'

  },
  methods: {




  },
  created() {


  }
})

export default estadoApp