import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const messages = {
	'es': {
		pasetituloQR: 'QR de acceso al parking',
		perfil: 'Perfil',

		tituloGestionReservas:'Gestión de reservas',
		correov2: 'Correo electronico',
		correoElectronicoNoValido: 'Correo electronico no valido',
		recuperarContrasenaTitulo: 'Recuperar contraseña',
		recuperar: 'Recuperar',
		inicioSesion: 'Iniciar sesión',
		enviamosCorreo: 'Enviamos correo',
		enviarmosCorreoTexto: 'Se ha enviado el correo de recuperación correctamente. Sigue los pasos para poder cambiar la contraseña.',
		correoNoValidoOlvido: 'Correo no valido',
		correoNoValidoOlvidoTexto: 'Este correo no existe en nuestros sistemas.',
		correoNoValido: 'Correo electronico no valido',
		tituloCerrarSesion: '¿Deseas cerrar sesión?',
		errorEnvioCorreoTexto: 'Hemos tenido un problema al enviarte el correo, intentalo mas tarde.',
		modificarContrasena: 'Modificar contraseña',
		requisitosContrasena: 'Requisitos de la contraseña',
		requisitosContrasena1: '- Contraseñas no coincidentes',
		requisitosContrasena2: '- Contraseñas coincidentes',
		requisitosContrasena3: '- Se necesitan 8 caracteres mínimo',
		requisitosContrasena4: '- Número mínimo de caracteres correcto',
		errorEnvioCorreo: 'Fallo al enviar correo',
		contrasenaNueva: 'Contraseña nueva',
		confirmarContrasenaNueva: 'Confirmar contraseña nueva',
		guardarContrasena: 'Guardar Contraseña',
		volverAlInicio: 'Volver al inicio',
		contrasenaCambiada: 'Contraseña cambiada',
		contrasenaCambiada2: 'Se ha cambiado la contraseña correctamente.',
		correoIncorrecto: 'Correo incorrecto',
		correoIncorrecto2: "Este correo no existe en nuestros sistemas. Por favor, ponte en contacto con tu Gestor para que puedas usar esta app",
		registroUsuario: "Registro de usuario",
		registroUsuario2: "Hemos verificado que tu correo no esta registrado en nuestra app. Recibirás un correo con la información de tu usuario para que puedas acceder a la aplicación",
		eliminar: 'Eliminar',
		sinReservas: 'Sin reservas',
		plazaCedida: 'Plaza cedida',
		sinCesiones: 'Sin cesiones',
		cesionesActivas: 'Cesiones activas',
		reservasActivas: 'Reservas activas',
		detalles: 'Detalles',
		anularReserva: 'Anular reserva',
		anularReserva2: '¿Deseas anular la reserva con número de localizador ',
		requerimientosCaracteres: 'Se necesitan 8 caracteres minimo',
		requerimientoCoincidencias: 'No coinciden las contraseñas',
		requerido: 'Requerido',

		informacionPaseTitulo: 'Pase asociado:',
		zonaPlaza: 'Zona del parking',
		tipoPaseTitulo: 'Tipo',
		fechaEntrada: "Fecha de entrada",
		fechaSalida: "Fecha de salida",
		zonaAparcamiento: "Zona de aparcamiento",
		btnReservar: 'Reservar',
		reservaplaza: 'Reserva una plaza',
		confirmarReserva: 'Finalizar tu reserva',
		eligePlaza: 'Selecciona una plaza disponible (se te asignara una al azar si no seleccionas ninguna).',
		confirmarReservapt1: '¿Quieres confirmar la reserva desde el día ',
		confirmarReservapt2: ' hasta ',
		confirmarReservapt3: ' ?',
		btnConfirmar: 'Confirmar',
		misReservas: 'Mis reservas',
		btnCerrar: 'Cerrar',
		tituloCederPlaza: 'Cesión de plaza',
		fechaFinalCesion: 'Fecha final',
		fechaInicioCesion: 'Fecha inicio',
		plazaAsignada: 'Plaza asignada',
		btnCeder: 'Ceder',
		motivo: 'Motivo de la cesión',
		tituloEditarUsuario: 'Editar contraseña',
		nombreEditarUsuario: 'Nombre',
		idiomaEditarUsuario: 'Selecciona el idioma de la app',
		contrasena: 'Nueva contraseña',
		contrasenaRepetir: 'Repetir contraseña nueva',
		btnGuardar: 'Guardar',
		olvidasteContrasena: '¿Olvidaste la contraseña?',
		identificadoresAsociados: 'Identificadores asociados:',
		matriculasTitulo: 'Matriculas:',
		sinMatriculas: 'Sin matriculas asociadas',
		contactlessTitulo: 'Contactless:',
		sinContactless: 'Sin contactless asociados',
		btnEditarUsuario: 'Editar Usuario',
		btnCerrarSesion: 'Cerrar sesion',
		botnavReservar: 'Reservar',
		botnavCesion: 'Cesiones',
		botnavUsuario: 'Usuario',
		reservaConfirmadaDialogo: 'La reserva se ha realizado con exito.',
		enhorabuenaDialogo: '¡Enhorabuena!',
		enhorabuenaDialogoCesion: '¡Gracias por realizar la cesión!',
		datosIncompletosDialogo: 'Datos incompletos',
		datosIncompletosDialogop2: 'No has introducido todas las fechas. Añade las fechas de inicio/final para poder continuar.',
		cesionPlazaDialogo: 'Cesión de la plaza',
		cesionPlazaDialogopt1: '¿Estas seguro que quieres ceder la plaza desde el ',
		cesionPlazaDialogopt2: ' hasta el ',
		si: 'Si',
		no: 'No',
		errorInicioSesionOperador: 'Operador o contraseña incorrectos',
		cesionCompletadaConExitoDialogo: 'La cesión se ha realizado con éxito',
		sinPlazasDialogo: 'Sin plazas disponibles',
		sinPlazasDialogopt1: 'No hay plazas disponibles para las fechas seleccionadas, por favor selecciona otras.',
		errorAlSolicitarReservaDialogo: 'Error al solicitar la reserva',
		errorAlSolicitarReservaDialogopt1: 'Las fechas seleccionadas no son validas para poder realizar la reserva.',
		errorAlSolicitarCesionDialogo: 'Error al solicitar la cesión',
		errorAlSolicitarCesionDialogopt1: 'Las fechas seleccionadas no son validas para poder realizar la cesión.',
		errorAlConfirmarReservaDialogo: 'Error al realizar la reserva',
		errorAlConfirmarReservaDialogopt1: 'No se ha podido realizar la reserva, disculpe las molestas. Gracias',
		inicioSesionTitulo: 'Iniciar sesión',
		correo: 'Correo electronico',
		contra: 'Contraseña',
		siguiente: 'Siguiente',
		inicio: 'Inicio',
		errorInicioSesion: 'Error de inicio de sesión',
		ingresar: 'Ingresar',
		aceptar: 'Aceptar',
		localizador: 'Localizador',
		tituloPasadoHoras: 'Exceso de tiempo de reserva',
		textoPasadoHoras: 'Has superado el tiempo maximo por reserva. Tu pase esta asociado a reservas con una duración maxima de  ',
		horas: " horas por cada reserva.",
		fechaCreacion: 'Fecha de creación',
		zona: 'Zona de aparcamiento',
		misCesionesActivos: 'Mis cesiones activas',
		escribirContrasena: 'Escribir contraseña',
		miPlaza: 'Plaza asignada',
		errorSolicitarReservaMaximo: 'Maximas reservas activas realizadas',
		errorSolicitarReservaMaximoTexto: 'Has llegado al límite maximo de reservas en curso. Para poder seguir realizando reservas, es necesario que caduque alguna de las que tienes en curso.'
	},	// fin traduccion a español

	'en': {
		tituloGestionReservas:'Reservation management',
		inicio: 'Home',
		ingresar: 'Enter',
		olvidasteContrasena: 'Forgot your password?',
		recuperarContrasenaTitulo: 'Password recovery',
		recuperar: 'Retrieve',
		inicioSesion: 'Log in',
		enviamosCorreo: 'We send mail',
		enviarmosCorreoTexto: 'The recovery email has been sent successfully. Please follow the steps to change your password.',
		correoNoValidoOlvido: 'Invalid email',
		correoNoValidoOlvidoTexto: 'This email does not exist in our systems.',
		correoNoValido: 'Invalid email address',
		tituloCerrarSesion: 'Do you wish to log out?',
		errorEnvioCorreoTexto: 'We had a problem sending you the email, please try again later.',
		modificarContrasena: 'Change password',
		requisitosContrasena: 'Password requirements',
		requisitosContrasena1: '- Mismatched passwords',
		requisitosContrasena2: '- Matching passwords',
		requisitosContrasena3: '- 8 characters minimum required',
		requisitosContrasena4: '- Correct minimum number of characters',
		errorEnvioCorreo: 'Failure to send mail',
		contrasenaNueva: 'New password',
		confirmarContrasenaNueva: 'Confirm new password',
		guardarContrasena: 'Save Password',
		volverAlInicio: 'Back to top',
		contrasenaCambiada: 'Password changed',
		contrasenaCambiada2: 'Password has been changed successfully.',
		correoIncorrecto: 'Incorrect mail',
		correoIncorrecto2: "This email does not exist in our systems, please contact the manager so that you can use this app.",
		registroUsuario: "User registration",
		registroUsuario2: "We have verified that your email is not registered in our app, we have sent you an email with your user information so you can access the application.",
		eliminar: 'Delete',
		sinReservas: 'Unreservedly',
		plazaCedida: 'Ceded square',
		sinCesiones: 'No cessions',
		cesionesActivas: 'Active transfers',
		reservasActivas: 'Active reserves',
		detalles: 'Details',
		anularReserva: 'Cancel reservation',
		anularReserva2: 'Do you wish to cancel the booking with a locator number ',
		requerimientosCaracteres: '8 characters minimum required',
		requerimientoCoincidencias: 'Passwords do not match',
		requerido: 'Required',
		correoElectronicoNoValido: 'Invalid email address',

		informacionPaseTitulo: '- Associated pass:',
		tipoPaseTitulo: 'Type',
		fechaEntrada: "Date of entry",
		fechaSalida: "Exit date",
		zonaAparcamiento: "Parking area",
		btnReservar: 'Reserve',
		reservaplaza: 'Reserve your place',
		confirmarReserva: 'Finalize your reservation',
		eligePlaza: 'Select an available seat (you will be randomly assigned a seat if you do not select any).',
		confirmarReservapt1: 'Do you want to confirm your reservation from ',
		confirmarReservapt2: ' to ',
		confirmarReservapt3: ' ?',
		btnConfirmar: 'Confirm',
		misReservas: 'My reservations',
		btnCerrar: 'Close',
		tituloCederPlaza: 'Transfer of your place',
		fechaFinalCesion: 'Final date',
		fechaInicioCesion: 'Start date',
		plazaAsignada: 'Assigned position',
		btnCeder: 'Ceder',
		motivo: 'Reason for assignment',
		tituloEditarUsuario: 'Edit password',
		nombreEditarUsuario: 'Name',
		idiomaEditarUsuario: 'Select the language of the app',
		contrasena: 'New password',
		contrasenaRepetir: 'Repeat new password',
		contrasenaRepetir: 'Repeat new password',
		btnGuardar: 'Save',
		identificadoresAsociados: 'Associated identifiers:',
		matriculasTitulo: '- Car liCar license plates:',
		sinMatriculas: '- No associated license plates',
		contactlessTitulo: '- Contactless:',
		sinContactless: '- No contactless partners',
		btnEditarUsuario: 'Edit User',
		btnCerrarSesion: 'Logout',
		botnavReservar: 'Reserve',
		botnavCesion: 'Assignments',
		botnavUsuario: 'User',
		reservaConfirmadaDialogo: 'The reservation has been successfully confirmed',
		enhorabuenaDialogo: '¡Congratulations!',
		datosIncompletosDialogo: 'Incomplete data',
		datosIncompletosDialogop2: 'You have not entered the dates correctly',
		cesionPlazaDialogo: 'Assignment of the seat',
		cesionPlazaDialogopt1: '¿Are you sure you want to give up your place from the ',
		cesionPlazaDialogopt2: ' to ',
		si: 'Yes',
		no: 'No',
		siguiente: 'Next',
		cesionCompletadaConExitoDialogo: 'The assignment has been successfully completed',
		sinPlazasDialogo: 'No vacancies',
		sinPlazasDialogopt1: 'There are no places available for these dates, please select other dates.',
		errorAlSolicitarReservaDialogo: 'Error when requesting reservation',
		errorAlSolicitarReservaDialogopt1: 'Please indicate the dates correctly in order to make the reservation.',
		errorAlConfirmarReservaDialogo: 'Error when confirming reservation',
		errorAlConfirmarReservaDialogopt1: 'We had a problem confirming the reservation, please try again. Thank you',
		inicioSesionTitulo: 'Login',
		correo: 'E-mail address',
		correov2: '- E-mail address',
		contra: 'Password'
	},	// fin traduccion a ingles

	'fr': {
		informacionPaseTitulo: 'Información del pase',


	},	// fin traduccion a frances

	'ca': {
		informacionPaseTitulo: 'Información del pase',
	}	// fin traduccion a catalan
};

export default new VueI18n({
	locale: 'es', 			// set locale
	fallbackLocale: 'es', 	// set fallback locale
	messages 				// set locale messages
});
